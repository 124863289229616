












































































import { Vue, Component, Prop } from 'vue-property-decorator'
import MutationForm from '@/components/form/MutationForm.vue'

@Component({
  components: { MutationForm }
})
export default class CollectionCreate extends Vue {
  /** Current Environment ID */
  @Prop({ type: String, required: true }) environmentId!: string
  /** Accent Color */
  @Prop({ type: String, default: 'primary' }) accentColor!: string

  get primaryColor() {
    return (this.$vuetify.theme.currentTheme.primary as any).base
  }
}
